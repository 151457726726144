<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<script>
export default {
  mounted() {
    this.$loadScript("assets/vendor/jquery/jquery.min.js").then(() => {
      this.$loadScript(
        "assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
      ).then(() => {
        this.$loadScript("assets/vendor/jquery.easing/jquery.easing.min.js").then(() => {
          this.$loadScript("assets/vendor/php-email-form/validate.js").then(() => {
            this.$loadScript("assets/vendor/waypoints/jquery.waypoints.min.js").then(() => {
              this.$loadScript("assets/vendor/isotope-layout/isotope.pkgd.min.js").then(() => {
                this.$loadScript("assets/vendor/venobox/venobox.min.js").then(() => {
                  this.$loadScript("assets/vendor/owl.carousel/owl.carousel.min.js").then(() => {
                    this.$loadScript("assets/vendor/aos/aos.js").then(() => {
                      this.$loadScript("assets/js/main.js").then(() => {
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });

  },
};
</script>
